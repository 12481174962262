import React, {useState} from 'react'
import { Outlet } from 'react-router-dom';

import Header from "./Header";
import Sidebar from "./Sidebar";

export default function DefaultLayout(){
    return(
        <>
            <div className='flex h-screen bg-gray-50 antialiased text-gray-700'>
                <Sidebar/>

                <div className='flex flex-col flex-1 w-full overflow-y-auto'>
                    <Header/>
                    <div className='px-5 py-10'>
                        <Outlet/>
                    </div>
                </div>
            </div>

        </>
    )
}