import React, {lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';

import DefaultLayout from "../components/layout/DefaultLayout";
import LazySpinner from "../components/LazyLoader";

import PrivateRoute from "../utils/authConfig";

const Dashboard = lazy(() => import('../pages/Dashboard'));

//Careers
const AllPostings = lazy(() => import('../pages/careers/AllPostings'));
const CreatePosting = lazy(() => import('../pages/careers/CreatePosting'));
const ViewPosting = lazy(() => import('../pages/careers/ViewPosting'));
const ViewApplicant = lazy(() => import('../pages/careers/ViewApplicant'));
const EditPosting = lazy(() => import('../pages/careers/EditPosting'));

export default function UserRoutes(){
    return(
        <>

            <Suspense fallback={<LazySpinner />}>
                <Routes>
                    <Route path="manage/*" element={<DefaultLayout />}>
                        <Route path="dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                        <Route path="careers/all-postings" element={<PrivateRoute><AllPostings /></PrivateRoute>} />
                        <Route path="careers/create-posting" element={<PrivateRoute><CreatePosting /></PrivateRoute>} />
                        <Route path="careers/posting/:jobcode" element={<PrivateRoute><ViewPosting /></PrivateRoute>} />
                        <Route path="careers/posting/:jobcode/edit" element={<PrivateRoute><EditPosting /></PrivateRoute>} />

                        <Route path="careers/applicant/:applicant_id" element={<PrivateRoute><ViewApplicant /></PrivateRoute>} />
                    </Route>
                </Routes>
            </Suspense>

        </>
    )
}