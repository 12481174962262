import React from 'react';
import {Routes, Route} from 'react-router-dom';

import AuthRoutes from "./routes/AuthRoutes";
import UserRoutes from "./routes/UserRoutes";
import Home from './pages/Home';

function App() {
  return (
    <>

    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>

      <AuthRoutes />
      <UserRoutes />

    </>
  );
}

export default App;
