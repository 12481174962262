import React, {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

import LazySpinner from "../components/LazyLoader";

const Login = lazy(() => import('../pages/authentication/Login'));

export default function AuthRoutes(){
    return(
        <>
            <Suspense fallback={<LazySpinner />}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </Suspense>
        </>
    )
}
