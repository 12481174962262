import React from 'react';
import {Link} from 'react-router-dom';
import { HiHome, HiMiniUsers, HiMiniTag, HiMiniChartBar, HiLink, HiMiniCog6Tooth } from "react-icons/hi2";

export default function Sidebar(){
    return(
        <>
            <div className="flex h-screen flex-col justify-between border-e">
                <div className="px-4 py-6 w-[250px]">

                    <Link to="/manage/dashboard"><span className="text-lg font-bold">Carnage Admin</span></Link>

                    <ul className="mt-5 space-y-1">
                        <li>
                            <Link to="/manage/dashoard" className="block rounded-lg bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 flex items-center">
                                <span className='mr-2'><HiHome/></span>Dashboard</Link>
                        </li>

                        <li className="">
                            <details className="group [&_summary::-webkit-details-marker]:hidden">

                                <summary
                                    className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                    <Link to="/manage/careers/all-postings"><span className="text-sm font-medium flex items-center">  <span
                                        className='mr-2'><HiMiniUsers /></span> Careers </span></Link>
                                    <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"/>
                            </svg>
                            </span>
                                </summary>


                                <ul className="mt-2 space-y-1 px-4">
                                    <li>
                                        <Link to="/manage/careers/create-posting"
                                           className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                            Create Job Posting
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/manage/careers/all-postings"
                                           className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                            View all postings
                                        </Link>
                                    </li>
                                    <li>
                                        <a href=""
                                           className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                            View all applicants
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ul>

                </div>

                <div className="sticky inset-x-0 border-gray-100 p-4">
                    <a href="" className="text-sm flex items-center text-gray-500"><span
                        className='mr-2'><HiMiniCog6Tooth/></span> Settings</a>
                </div>
            </div>
        </>
    )
}